#wrapper {
  min-width: 100%;
  overflow-x: hidden;
}

.success-header-container {
  height: 80px;
  background: #0065ff;
  border-radius: 0px 0px 24px 24px;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.success-inner-header {
  padding: 0 100px;
  display: flex;
  align-items: center;
}

.success-logo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nordiqo-logo {
  width: 60px;
  filter: brightness(0) invert(1) sepia(1) saturate(0%) hue-rotate(180deg);
}

#main-content {
  position: static;
  margin: 0;
  padding: 50px 0 100px;
}

.h1-basic01 {
  font-weight: 500;
  font-size: 42px;
  line-height: 54px;
  color: #000000;
  margin: 0 0 24px;
  font-family: monospace;
}

.h4-basic01 {
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  color: #828e99;
  font-family: monospace;
}

.congratulations_center img {
  width: 200px;
}
.congratulations_center .figure {
  margin: 50px 0 0;
}

.default-bank-connection-result {
  position: relative;
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 9999;
}
.connection-img {
  width: 100%;
}

.body_bg_top_right_center {
  background: url("../../../../public/assets/images/bg-right-circle.svg")
    no-repeat scroll right center / 250px 350px;
}
