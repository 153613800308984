.pagination-container {
  text-align: center;
  margin-top: 10px;
}

.pagination {
  display: inline-block;
  padding: 5px;
}

.pagination button {
  background-color: #f08000;
  color: black;
  border: 1px solid #ddd;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  margin: 2px;
  border-radius: 5px;
}

.pagination button.active {
  background-color: #f08000;
}

.pagination-button:not(.active) {
  background-color: white;
}
