.logo {
    color: aliceblue;
    font-weight: 700;
    font-size: 23px;
}
.logo-text {
    color: #ff4545;
    font-weight: 700;
    font-size: 23px;
}
.logo-div {
    display: flex;
    column-gap: 5px;
    margin-left: 8px;
    margin-top: 10px;
}
.left {
    position: fixed;
    left:0;
    z-index: 1;

}
.toggle-button {
    background-color: white;
}
