/* .table-t {
  color: white;
  background-color: red;
}
.btn-add {
  background-color: red;
  color: white;
  font-weight: 700;
  border-radius: none;
}
.table-link {
  text-decoration: none;
} */

/* ul.react-paginate {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
}

ul.react-paginate li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: transparent;
}
ul.react-paginate li.selected a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
ul.react-paginate li.disabled a {
  color: grey;
}
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default;
} */

/* .pagination-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
  border-color: transparent;
  /* background-color: #0366d6; */
/* border-color: transparent;
  color: white;
  min-width: 32px;
  color: grey;
  cursor: default;
} */

/* .paginateclass {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
  border-color: transparent;
  color: white;
  min-width: 32px;
  color: grey;
  cursor: default;
} */

.pagination-container .paginateclass {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination.paginateclass {
  display: flex;
  list-style: none;
  cursor: pointer;
}

.pagination li paginateclass {
  margin: 0 5px;
}

.pagination li a paginateclass {
  text-decoration: none;
  color: #333;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination li a:hover paginateclass {
  background-color: #f0f0f0;
}

.pagination li.active a paginateclass {
  background-color: #007bff;
  color: #fff;
}