body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn_danger {
  color: #f33155;
  border: none !important;
  background: none !important;
}
.btn_info {
  color: #41b3f9;
  border: none;
  background: none;
}
.btn_primary {
  color: #7460ee;
  border: none;
  background: none;
}
.btn_success {
  color: #20f70f;
  border: none;
  background: none;
}
.btn_warning {
  color: #3c763d;
  border: none;
  background: none;
}
.radius {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #888888;
  margin-bottom: 8%;
}
.input-icons {
  width: 100%;
  position: relative;
}

.input-icons i {
  position: absolute;
  font-size: 12px;
  padding: 7px;
  color: #262d48;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icons input {
  padding-left: 30px !important;
}

.btn.primary_button {
  background-color: #ff0000;
  color: #ffffff;
  font-size: 11px;
  border: 0;
  font-weight: 650;
  padding: 10px 20px;
  transition: all 0.5s;
  -webkit-box-shadow: 2px 10px 5px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 2px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 20%);
}
