.active-link {
  color: #e22e2e;
}
a.active {
  background: #F08000 !important;
}
.header {
  height: 100px;
  background: rgb(39, 16, 16);
  padding: 0 20px;
  color: rgb(250, 250, 250);
  display: flex;
  align-items: center;

}
/* .nav li a:hover {
  background-color: red;
} */
.logo {
  float: left;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  margin-left: auto;
  justify-content: center;
}

.menu a {
  color: rgb(250, 250, 250);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 10px;
}

.show-menu-btn,
.hide-menu-btn {
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  display: none;
}

.show-menu-btn {
  margin-left: auto;
}

.show-menu-btn i {
  line-height: 100px;
}
/* a.active {
  background: red;
} */
#side-menu ul > li > a:hover{
  background-color: #F08000 !important;
}
.menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover {
  color: rgb(151, 151, 151);
}

#chk {
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

@media screen and (max-width: 800px) {
  .show-menu-btn,
  .hide-menu-btn {
    display: block;
  }
  .menu {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(39, 16, 16);
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    z-index: 3;
  }
  .menu a {
    display: block;
    padding: 20px;
  }

  .hide-menu-btn {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  #chk:checked ~ .menu {
    right: 0;
  }
}
.top-left-part {
  margin-top: 7px !important;
  background: #000;
}

.logout:hover{
  cursor: pointer;
  background-color: #F08000;
}
#side-menu li a {
  display: inline-block;
  color: #ffffff;
  border-left: 0px solid #fff;
}