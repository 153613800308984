/* .loader_inner {
    border: 5px solid #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.9s linear infinite;
}
@media only screen and (max-width:1200px){
    .container{
        width: 100%;
    }
}
.container {
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    font-family: Helvetica;
  }
  .loader {
    height: 20px;
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    border: 2px solid white;
  }
  .loader--dot:first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  .loader--dot:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  .loader--dot:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  .loader--dot:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  .loader--dot:nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  .loader--dot:nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
  .loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
  }
  .loader--text:after {
    content: "Loading";
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  
  @keyframes loader {
    15% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(230px);
    }
    65% {
      transform: translateX(230px);
    }
    95% {
      transform: translateX(0);
    }
  }
  @keyframes loading-text {
    0% {
      content: "Loading";
    }
    25% {
      content: "Loading.";
    }
    50% {
      content: "Loading..";
    }
    75% {
      content: "Loading...";
    }
  } */

.backgroundAndHeight{
  position: relative;

}
  .loader {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 700px;
    top: 50%;
    animation: rotate 1s linear infinite;
    transform: translateY(-50%);
  }
  .loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
  }
  .loader::after{
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: #FF3D00;
  }

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  }

 