html,
body {
  width: 100%;
  height: 100%;
  background-color: #ecf0f4;
}
/* body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    	background: url(bg.jpg);
    background-image: linear-gradient(to right top, #1db9ff, #a2a0ff, #fb7ad4, #ff677a, #f48603);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.box {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 40px;
  background: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.box h4 {
  margin: 0 0 30px;
  padding: 0;
  color: #ffd700;
  text-align: center;
}

.box .inputBox {
  position: relative;
  margin-top: 10px;
}
.box .inputBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.box .inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
  animation: LabelOnLoad 1s forwards 0s ease;
}
@keyframes LabelOnLoad {
  0% {
    transform: rotate(0) translateY(-19px);
    opacity: 0;
  }
  100% {
    transform: rotate(0) translateY(0);
    opacity: 1;
  }
}
.box .inputBox input:focus ~ label,
.box .inputBox input:valid ~ label {
  top: -19px;
  left: 0;
  color: #ffd700;
  font-size: 12px;
}
.box button[type="submit"] {
  background: transparent;
  border: none;
  position: relative;
  outline: none;
  color: #ffd700;
  background: transparent;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  width: 28%;
  /* justify-content: center; */
}
.button_loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.button_loader_inner {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 50%;
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  border-left-color: transparent;
  animation: spin 0.9s linear infinite;
}
/* @media(max-width: 720px){
	.box {
		width: 80%;
	}
} */
@media (max-width: 820px) {
  .box {
	
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 40px;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
}
@media (max-width: 620px) {
  .box {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 40px;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
}

.boxFooter {
  position: absolute;
  top: 98%;
  left: 98%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 40px;
  /* background: rgba(0,0,0,.4); */
  box-sizing: border-box;
  /* box-shadow: 0 15px 25px rgba(0,0,0,.5); */
  border-radius: 10px;
}

.boxFooterText {
  padding-left: 22%;
  margin-bottom: 30%;
  color: aliceblue;
  font-weight: lighter;
}

.signUp {
  color: #fff;
}

.color {
  color: #fff;
}
.input-error {
  margin-bottom: 30px;
  margin-top: -10px;
  color: #ffd700;
  font-weight: 400;
  font-size: 12px;
}
.inputvalue {
  background-color: transparent;
}
